import React from 'react'
import { graphql } from 'gatsby'
import BlockTextSans from '../components/block-content/block-text-sans'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import TextContainer from '../components/text-container'
import PageIntro from '../components/page/page-intro2'
import { PropTypes } from 'prop-types'

export const query = graphql`
  query LicensePageQuery {
    page: sanityPage(slug: { current: { eq: "license" } }) {
      id
      title
      _rawBody
      excerpt
    }
  }
`

const LicensePage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data && data.page

  if (!page) {
    throw new Error(
      'Missing "License" page data. Open the studio at http://localhost:3333 and add "License" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={page.title} />
      <Container>

        <PageIntro
          title={page.title}
          excerpt={page.excerpt}
          centered={true}
        />

        <TextContainer >
          <BlockTextSans blocks={page._rawBody || []} />
        </TextContainer>

      </Container>
    </Layout>
  )
}

LicensePage.defaultProps = {
  data: {
    page: {
      title: 'No title',
    },
  },
}

LicensePage.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.array,
}

export default LicensePage
